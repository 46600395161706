const pt = {
  app_name: "PassApp Web",
  error: "Erro",
  email: "E-mail",
  password: "Senha",
  repeat_password: "Repetir senha",
  password_validation_length: "Comprimento da senha: pelo menos 8 caracteres.",
  password_validation_numbers: "Caracteres numéricos: pelo menos 1 número.",
  password_validation_symbols: "Caracteres especiais: pelo menos 1 caractere especial.",
  password_validation_lower: "Letras minúsculas: pelo menos 1 letra minúscula.",
  password_validation_upper: "Letras maiúsculas: pelo menos 1 letra maiúscula.",
  login: "Insira",
  invalid_login: "Usuário ou senha incorretos",
  my_passes: "Meus Passes",
  my_guests: "Meus convidados",
  my_notifications: "Minhas notificações",
  validate_passes: "Validar Passe",
  video_tutorials: "Perguntas frequentes",
  permanent_passes: "Permanentes",
  access_prevalidation_status_success: "Dados pré-validados",
  access_prevalidation_status_error: "Requer pré-validação",
  occasional_passes: "Ocasionais",
  error_getting_passes: "Erro ao obter os passes",
  invited: "Convidado",
  no_invited: "Ainda não há convidados",
  pass_status_accepted: "Aceito",
  pass_status_used: "Usado",
  pass_status_pending: "Solicitação pendente de aprovação",
  pass_status_pending_approval: "Passe pendente de aprovação",
  auth_status_pending_approval: "Aviso pendente de aprovação",
  pass_status_accepted_label: "Passe aceito",
  pass_status_used_label: "Passe usado",
  pass_status_pending_label: "Passe pendente",
  pass_status_rejected_label: "Passe recusado",
  pass_status_cancelled_label: "Passe cancelado",
  pass_status_finished_label: "Passe finalizado",
  pass_status_rejected: "Recusado",
  pass_status_cancelled: "Cancelado",
  pass_status_finished: "Finalizado",
  new_invitation: "Novo convite",
  share: "Compartilhar",
  share_link_copied: "Link para compartillhar copiado",
  copy_link: "Copiar link do convite",
  choose_destination: "Selecione o destino",
  authorized_until: "Autorizado até",
  authorized_from: "Autorizado desde",
  permanent: "Permanente",
  occasional: "Ocasional",
  particular: "Particular",
  provider: "Fornecedor",
  comment: "Comentário",
  send_invite: "Enviar convite",
  pass_type: "Tipo de Passe",
  guest_type: "Tipo de Convidado",
  day_letter_monday: "S",
  day_letter_tuesday: "T",
  day_letter_wednesday: "Q",
  day_letter_thursday: "Q",
  day_letter_friday: "S",
  day_letter_saturday: "S",
  day_letter_sunday: "D",
  days: "Dias",
  allowed_times_label: "Horários de entrada permitidos",
  all_day: "Dia inteiro",
  entry_time_label: "Entre as",
  out_time_label: "e as",
  unselected: "Sem selecionar",
  must_select_destination: "Você deve selecionar uma empresa ou complexo",
  invalid_dni: "O documento pode ter apenas letras e números",
  error_pass_length: "O Passe não pode ter duração maior que 12 horas.",
  error_auth_length:
    "A autorização não pode ter um intervalo de duração maior que 12 horas",
  error_end_before_start:
    "A data de finalização deve ser posterior a de início",
  error_select_day: "Você deve selecionar ao menos 1 dia",
  error_out_before_entry: "O horário de entrada deve ser anterior ao de saída",
  error_max_permanent_passes_user:
    "Seu usuário atingiu o número máximo de passes permanentes",
  error_max_permanent_passes:
    "O número máximo de passes permanentes foi atingido",
  update_invite: "Atualizar Passe",
  update: "Atualizar",
  cancel_authorization: "Cancelar convite",
  cancel_invite: "Cancelar Passe",
  pass_updated_successfully: "Passe atualizado com sucesso",
  search_passes: "Procurar Passes",
  notifications: "Notificações",
  accept: "Aceitar",
  pass_created: "Passe criado com sucesso",
  pass_link_created:
    "Você deve copiar e enviar o seguinte link ao convidado para que complete o processo de registro:",
  decline: "Recusar",
  pass_request: "Solicitação de Passe",
  no_comments: "Sem comentários",
  invite_accepted: "Convite aceito com sucesso",
  invite_rejected: "Convite recusado com sucesso",
  invite_detail: "Detalhe do convite",
  invite_accept_error: "Erro ao aceitar solicitação",
  invite_reject_error: "Erro ao recusar solicitação",
  invite_update_error: "Erro ao atualizar convite",
  invite_create_error: "Erro ao criar convite",
  invite_cancel_error: "Erro ao cancelar convite",
  dynamic_link_error: "Erro ao criar o link de passe",
  invite_cancel: "Convite cancelado com sucesso",
  logout: "Sair",
  error_getting_user_info: "Erro ao obter as informações do usuário",
  error_getting_notifications: "Erro ao obter as notificações",
  tutorials: "Tutoriais",
  i_am_visitor: "Sou visitante",
  i_want_to_invite: "Quero convidar",
  link_copied: "Link copiado",
  forgot_password: "Esqueceu sua senha?",
  recover_password: "Recuperar minha senha",
  password_changed: "Senha alterada com sucesso",
  enter_email: "Insira seu e-mail",
  temp_password: "Senha temporária",
  repeat_mail: "Repetir e-mail",
  new_password: "Nova senha",
  confirm_password: "Confirmar senha",
  error_sending_email: "Erro ao enviar e-mail de convite",
  recover_password_email_sent:
    "Enviamos uma senha temporária no seu e-mail para que possa escolher uma nova senha",
  error_updating_user: "Erro ao atualizar os dados do usuário",
  passwords_dont_match: "As senhas não correspondem",
  email_dont_match: "El Email y su confirmación no coinciden", //En revision
  send_pass: "Enviar Passe ao convidado",
  notify_security: "Avisar Portaria",
  error_getting_authorizations: "Erro ao obter as autorizações do usuário",
  authorization_expired: "Expirado",
  revoked: "Revogado",
  name: "Nome",
  lastName: "Sobrenome",
  dni_number: "Número do documento",
  dni_country: "País do documento",
  admin_comment: "Comentários do administrador",
  guest_company: "Empresa do convidado",
  guest_email: "E-mail do convidado",
  assign_parking: "Atribuir garagem de cortesia aos convidados",
  send_to_security: "Enviar a portaria",
  search_country: "Procurar país..",
  error_adding_authorization: "Erro ao criar autorização",
  error_updating_authorization: "Erro ao atualizar autorização",
  error_cancelling_authorization: "Erro ao cancelar autorização",
  authorization_added: "Autorização criada com sucesso",
  authorization_updated: "Autorização atualizada com sucesso",
  authorization_cancelled: "Autorização cancelada com sucesso",
  guest_amount: "Quantidade de convidados",
  cancel: "Cancelar",
  yes: "Sim",
  no: "Não",
  confirm_text: "Tem certeza que deseja realizar esta ação?",
  confirm: "Confirmar",
  delete_user: "Deseja eliminar {{user}} do convite?",
  confirm_cancel: "Tem certeza que deseja sair sem salvar as alterações?",
  confirm_cancel_pass: "Tem certeza que deseja cancelar este convite?",
  must_join_establishment:
    "Você deve ingressar em algum estabelecimento para pode enviar passes",
  global_options_error: "Erro ao obter as opções globais",
  friends_error: "Erro ao obter a lista de amigos",
  create_new_user: "Criar novo usuário",
  create_user_info: "Para criar um usuário preencha os seguintes dados",
  create_user_info_single:
    "Para ativar seu usuário preencha os seguintes dados",
  mail: "E-mail",
  number_example: "Exemplo: +54 11 1234 5678",
  number_warning: "Enviaremos um SMS para validar seu usuário.",
  accept_terms: "Aceito os {{terms}}",
  terms: "Termos e Condições",
  create_user: "Criar usuário",
  register_error: "Erro ao tentar criar usuário",
  two_factor_info:
    "Por favor, insira o código de verificação que enviamos para {{phone}}",
  wish_to_change: "Deseja alterar o número inserido?",
  must_accept_terms: "Você deve aceitar os termos e condições",
  send: "Enviar",
  resend_sms_code: "Reenviar código por SMS",
  receive_code_by_call: "Receber código por chamada telefônica",
  cancel_verification: "Cancelar verificação",
  enter_phone: "Insira novo número de telefone",
  change_phone_number: "Alterar número de telefone",
  confirm_verification_cancel:
    "Tem certeza que deseja cancelar a verificação da sua conta? Você não poderá voltar a esta etapa",
  error_requesting_verification: "Erro ao enviar o código",
  code_sent_successfully: "Código de verificação enviado com sucesso ",
  add_profile_photo: "Adicione uma foto em seu perfil",
  countries_and_buildings: "Condomínios e edifícios ",
  companies: "Empresas",
  join_company: "Associar-me a uma empresa",
  join_country: "Associar-me a um complexo",
  join_building: "Associar-me a um edifício",
  floor_number: "Número do andar ou departamento",
  personal_number: "Telefone particular",
  lot_number: "Número do lote",
  map_location: "Localize sua casa",
  map_location_info:
    "Clique dentro do mapa para indicar a localização exata de sua casa.",
  join: "Me tornar associado",
  error_choose_location: "Você deve localizar sua casa no mapa",
  error_no_particular_phone: "Você deve inserir seu telefone particular",
  error_no_lot_number: "Você deve inserir o número do lote",
  error_no_floor_number: "Você deve inserir o número do andar ou departamento",
  error_joining_establishment: "Erro ao tentar se associar",
  manager: "Administrador da empresa",
  company_location: "Localização da empresa",
  company_details: "Outros detalhes de localização (ex: andar, sala)",
  birth_date: "Data de nascimento",
  user_updated: "Usuário atualizado com sucesso",
  invalid_verification_code: "Código de verificação inválido",
  capture: "Tirar foto",
  upload_photo: "Fazer upload manualmente",
  capture_again: "Tirar outra foto",
  continue: "Continuar",
  register_joined_country_success:
    "Após preencher seus dados, sua solicitação de adesão será enviada ao administrador do complexo, e ficará pendente de autorização. ",
  joined_country_success:
    "Sua solicitação de adesão foi enviada ao administrador do complexo, e permanece pendente de aprovação.",
  joined_company_simplified:
    "Enviamos um e-mail para validar seu endereço de e-mail.",
  register_joined_company_simplified:
    "Após preencher seus dados, você receberá um e-mail de confirmação ",
  my_establishments: "Meus complexos associados",
  join_establishment: "Associar-me a um complexo",
  pending: "Pendente",
  updated_country: "Complexo atualizado com sucesso",
  must_select_company: "Você deve selecionar uma empresa",
  must_select_manager: "Você deve selecionar um administrador da empresa",
  search_establishment: "Procurar um complexo para se associar",
  search_pass: "Procurar um Passe",
  error_uploading_image: "Erro ao fazer o upload da imagem",
  max_guests_allowed: "Quantidade de convidados admitidos",
  please_select_max_guests:
    "Você deve indicar a quantidade de convidados que o passe aceitará",
  dialog_max_guests_allowed:
    "Indique a quantidade de convidados que o passe aceitará (considere os convidados que já são associados)",
  dialog_max_guests_error:
    "Indique uma quantidade de convidados maior para poder compartilhar o passe",
  please_specify_birthdate: "Você deve preencher sua data de nascimento",
  rows_per_page: "Linhas por página",
  all: "Todos",
  pass_mamager_confirm_status_accepted:
    "Tem certeza que deseja enviar o passe como Aceito?",
  pass_mamager_confirm_status_rejected:
    "Tem certeza que deseja enviar o passe como Recusado?",
  pass_mamager_confirm_status_canceled:
    "Tem certeza que deseja enviar o passe como Cancelado?",
  registers: "Registros",
  host: "Anfitrião",
  type: "Tipo",
  guests: "Convidado",
  date: "Data",
  hour: "hora",
  status: "Status",
  actions: "Ações",
  error_getting_PassManagerList: "Erro ao obter a lista de Passes",
  pass_status_error_current: "O Passe já foi solicitado",
  pass_status_error_notAllowed: "Não foi possível alterar o status do Passe",
  pass_status_changed: "Status do Passe alterado com sucesso",
  error_update_PassManager: "Erro ao enviar o status do Passe",
  undefined: "objeto não encontrado",
  pass_status_error_notAccepted: "O passe deve ser aceito",
  pass_parking_changed: "Estacionamento alterado",
  accepted: "Aceito",
  finished: "Finalizado",
  canceled: "Cancelado",
  rejected: "Recusado",
  reject: "Recusar",
  show_comment: "Comentários",
  company_staff: "Gerenciar equipe",
  company_staff_confirm_status_remove: "Deseja desvincular um funcionário?",
  company_staff_removed: "Funcionário desvinculado",
  company_staff_confirm_status_approve: "Aprovar solicitação?",
  company_staff_approved: "Solicitação aceita",
  company_staff_confirm_status_reject: "Recusar solicitação?",
  company_staff_rejected: "Solicitação recusada",
  company_staff_confirm_status_claim: "Deseja ser o gerente?",
  company_staff_your_namager: "Sou seu gerente",
  company_staff_remove: "Desvincular",
  see_personal: "Ver ficha pessoal",
  approve: "Aprovar",
  actived_person: "Equipe ativa",
  new_requests: "Novas solicitações",
  unmanaged_staff: "Equipe sem gerente",
  passapp_user_id: "ID do usuário PassApp",
  association_date: "Data de associação",
  photo: "Foto",
  dni: "CPF",
  phone: "Telefone",
  place: "Local",
  user_detail: "Ficha de usuário",
  special_permissions: "Permissões especiais",
  user_manager: "Admin de equipe",
  passes_validator: "Validador de Passe",
  room_reservation: "Reserva de auditório",
  parking_manager: "Admin de garagem",
  desk_manager: "Admin de escritórios",
  super_range: "Autoriza visitas",
  face_photo: "Foto de capa",
  backDNI_photo: "Foto do CPF (verso)",
  frontDNI_photo: "Foto do CPF (frente)",
  error_getting_CompanyStaff: "Erro ao obter a lista de equipe",
  error_saving_CompanyStaff: "Erro ao salvar funcionário",
  company_staff_personal_not_available: "Ficha não disponível",
  company_staff_personal_updated: "Ficha atualizada",
  select_a_pass_validator1: "Selecione o novo validador de passe",
  select_a_pass_validator2: "que substituirá a função deste usuário",
  select_a_staff_manager1: "Selecione o novo administrador da equipe",
  select_a_staff_manager2: "que substituirá a função deste usuário",
  error_getting_RoomReservation: "Erro ao obter a lista de Reservas",
  error_cancel_RoomReservation: "Erro ao cancelar a Reserva",
  error_get_simple_RoomReservation: "Erro ao obter a Reserva",
  error_save_RoomReservation: "Erro ao enviar a Reserva",
  error_upload_file_RoomReservation: "Erro ao enviar o arquivo da Reserva",
  save: "Salvar",
  search: "Procurar",
  no_result: "Não foram encontrados registros",
  date_time: "Data e Hora",
  instals: "Instalações",
  documents: "Documentos",
  admin_notes: "Comentários Admin",
  edit: "Editar",
  purchase_order: "Pedido de compra",
  no_purchase_order: "Sem pedido de compra",
  approved: "Aprovado",
  all_results: "Todos os resultados",
  room_reseration: "Reserva de auditório",
  download: "Fazer upload de arquivo",
  see_file: "Ver Arquivo",
  chairs: "CADEIRAS",
  categingTablesInside: "MESAS DE CAFÉ DENTRO",
  categingTablesOutside: "MESAS DE CAFÉ FORA",
  speakerTables: "MESAS DE SPEAKER",
  assistanceTables: "MESAS REACT. ARMADO",
  tableCloths: "TOALHA PARA MONTAGEM",
  staticScreens: "TELAS FIXAS",
  mobileScreens: "TELAS MÓVEIS",
  mobileProjectors: "PROJETORES MÓVEIS",
  microphone: "MICROFONES",
  multipleExtensions: "MULTIPLAS EXTENSÕES",
  error_TandC: "Você deve aceitar os Termos e Condições",
  error_sharedFacilities:
    "Você deve o fazer upload de pelo menos uma instalação compartilhada",
  error_date: "Você deve fazer o upload da data da reserva",
  error_beginTime: "Você deve fazer o upload do Horário de início da reserva",
  error_endTime: "Você deve fazer o upload do Horário de término da reserva",
  error_time_range:
    "Você deve fazer o upload de um horário entre as 08:00 e 18:00",
  error_time_range_2days:
    "As reservas devem ser feitas com mínino 48hrs de antedecedência",
  error_time_inverted:
    "O horário de término não pode ser menor do que o de início",
  error_mobiliario:
    "Por favor, preencha todos os campos da seção móveis com a quantidade desejada (pode ser zero) ",
  error_layout: "Montagem não disponível",
  room_not_avail: "Auditório não disponível",
  delete_file: "Eliminar arquivo anexo",
  reservation_owner: "Reserva feita por:",
  room_reserv_step1: "INDICAR DETALHES DA SUA RESERVA",
  room_reserv_step2: "INDICAR DETALHES DA MOBÍLIA",
  room_reserv_step3: "ANEXAR SEU PEDIDO DE COMPRA",
  room_reserv_step4: "ANEXAR OUTROS DOCUMENTOS",
  room_reserv_step5: "INSIRA SUAS OBSERVAÇÕES",
  beginTime: "Horário Início",
  endTime: "Horário Término",
  layout: "Montagem",
  sharedFacilities: "Instalações compartilhadas",
  mobiliario: "Mobília",
  sinMobiliario: "Sem mobília",
  notes: "Observações",
  without_notes: "Sem observações",
  room_reserv_current: "ESTA RESERVA",
  room_reserv_approved: "OCUPADA",
  room_reserv_pending: "PENDENTE DE APROVAÇÃO",
  text_TC_1: "Li e aceito os",
  text_TC_2: "Termos e Condições",
  layout_information_text_see: "Ver planos de montagem",
  layout_information_text: "Desenhos de montagem",
  dimension_information_text_see: "Ver dimensões da auditório",
  dimension_information_text: "Dimensões das auditório",
  loaded: "Upload realizado",
  check_data_before_to_continue: "Verifique os dados para continuar",
  text_without_purchase_order:
    "A reserva não contém um pedido de compra. Esta reserva irá expirar em 48hrs se um pedido de compra não for anexado antes.",
  from: "de",
  to: "a",
  updated_successfully_RoomReservation: "A reserva foi atualizada com sucesso",
  added_successfully_RoomReservation:
    "A solicitação de reserva foi enviada ao administrador das auditório para aprovação.",
  textDropzoneFile: "Arraste e solte os arquivos aqui...",
  textDropzoneFileInfo1: "10MB máximo",
  textDropzoneFileInfo2: "Arquivos compatíveis: pdf, jpg, png, txt",
  textDropzoneErrorFileSize: "O arquivo não pode ultrapassar 10MB",
  textDropzoneRejectedFileType: "Os arquivos compatíveis: pdf, jpg, png, txt",
  invalid_date_format: "Data inválida",
  invalid_time_format: "Hora inválida",
  text_other_files_RoomReservation:
    "Lembre-se que os certificados SAT devem estar acompanhados da cláusula de não repetição em nome da OPM INMOBILIARIA S.A. Cuit N 30-68583774-5, sem exceções.",
  assigned_user: "Usuário atribuído",
  assigned_users: "Usuários atribuídos",
  assigned_parking: "Garagem Atribuída",
  unsetParkingLotUser: "Cancelar atribuição de usuário",
  confirm_parkingLot_unsetParkingLotUser: "Cancelar atribuição de usuário?",
  confirm_parkingLot_remove: "Remover garagem com {{countParkings}} reservas?",
  confirm_parkingGroup_remove:
    "Remover grupo de garagem com {{countParkings}} reservas?",
  confirm_delete_parkingGroup: "Remover grupo de garangem?",
  confirm_uncheck_parkingGroup: "Remover usuário do grupo?",
  error_getting_parking: "Erro ao obter garagem",
  error_add_parkingLot: "Erro ao adicionar garagem",
  error_edit_parkingLot: "Erro ao editar a garagem",
  error_deleting_parking: "Erro ao excluir a garagem",
  error_getting_parking_group: "Erro ao obter os grupos de garagem",
  error_getting_parking_employees: "Erro ao obter a lista de usuários",
  error_add_parking_group: "Erro ao adicionar o grupo de garagem",
  error_updating_parking_group: "Erro ao editar o grupo de garagem",
  error_deleting_parking_group: "Erro ao adicionar o grupo de garagem",
  error_add_employeeParkingGroup: "Erro ao adicionar o usuário",
  error_remove_employeeParkingGroup: "Erro ao excluir usuário",
  error_getting_reservations_by_parkingLots:
    "Erro ao obter reservas por garagem",
  error_getting_reservations_by_details: "Erro ao obter reservas por detalhe",
  addNewName: "Incluir nova garagem",
  add_parking_lots: "Incluir garagem",
  edit_parking_lot: "Editar garagem",
  add_parking_group: "Incluir grupo de garagem",
  edit_parking_group: "Editar grupo de garagem",
  description: "Descrição",
  group: "Grupo",
  delete: "Eliminar",
  parking_reservation: "Reserva de garagem",
  export_to_excel: "Exportar",
  users: "Usuários",
  error_add_empty_parkingNames: "Você não fez upload de um nome de garagem",
  error_add_empty_parkingGroupNames:
    "Você não fez upload do nome de um grupo de garagem",
  parking_free: "Livre",
  parking_reserved: "Reservada",
  parking_canceled: "Cancelada",
  parking_approved: "Aprovado",
  parking_rejected: "Recusada",
  parking_used: "Utilizada",
  parking_unused: "Sem uso",
  parking_using: "Em uso",
  parking_released: "Liberada",
  parking_expired: "Expirado",
  parking_pending: "Pendente",
  parking: "Garagem",
  parkings: "Garagem",
  parking_groups: "Grupo de garagem",
  parking_reserv: "Reservas",
  parking_reports: "Relatórios de uso",
  parking_users: "Usuários",
  confirm_reject_parkingReserv: "Recusar reserva?",
  user: "Usuário",
  total: "Total",
  filter: "Filtrar",
  percentage: "Porcentagem",
  change_report_by_parkings: "Ver por grupo de garagem",
  change_report_by_users: "Ver por Usuários",
  report_user_employee: "Usuários<br/>Funcionário",
  report_user_employee_excel: "Usuários Funcionário",
  report_parkingLot: "Garagem<br/>Atribuída",
  report_parkingLot_excel: "Garagem Atribuída",
  report_total: "Total<br/>Reservas YTD",
  report_total_excel: "Total Reservas YTD",
  report_used: "Total Reservas<br/>Utilizadas",
  report_used_excel: "Total Reservas Utilizadas",
  report_percent: "% Utilização <br/> de Reservas",
  report_percent_excel: "% Utilização de Reservas",
  report_group: "Grupo",
  dete_from: "Desde",
  date_to: "Até",
  change_list_to_details: "Ver Detalhe",
  change_list_to_parkings: "Ver por garagem",
  status_reserved: "Reservada",
  status_approved: "Aprovado",
  status_canceled: "Cancelada",
  status_rejected: "Recusada",
  status_used: "Utilizada",
  status_unused: "Sem uso",
  status_using: "OCUPADA",
  status_acepted: "Aceito",
  status_pending: "Pendente",
  status_finished: "Finalizado",
  status_expired: "Expirado",
  reserv_create_date: "Data-Hora<br/>Geração",
  reserv_create_date_excel: "Data-Hora Geração",
  reserv_date: "Data<br/>Reservada",
  reserv_date_excel: "Data Reservada",
  reserv_user_employee: "Usuário<br/>Funcionário",
  reserv_user_employee_excel: "Usuário Funcionário",
  reserv_parkingLot: "Garagem<br/>Funcionário",
  reserv_parkingLot_excel: "Garagem Funcionário",
  reserv_modification_date: "Data-Hora<br/>Modificação",
  reserv_modification_date_excel: "Data-Hora Modificação",
  establishment_hour: "Horário do estabelecimento",
  until_date: "Até %%DATE%%",
  full_day_from_hour_to_hour:
    "%%DAY_NAME%% %%FULL_DAY%%, de %%FROM_HOUR%%hs a %%TO_HOUR%%hs",
  pagination: "%%FROM%%-%%TO%% de %%TOTAL%%",
  confirm_desk_unsetDesksUser: "¿Desasignar usuario?", //no usado
  confirm_desk_remove: "¿Remover escritorio con {{countDesks}} reservas?",
  confirm_deskGroup_remove:
    "Remover grupo de escritórios com {{countDesks}} reservas?",
  confirm_reject_DeskReserv: "Recusar reserva?",
  desk: "Escritório",
  desks: "Escritórios",
  desks_reservation: "Reserva de escritórios",
  desks_groups: "Grupos de escritórios",
  desks_reserv: "Reservas",
  desks_reports: "Relatórios de uso",
  desks_users: "Usuários",
  desk_free: "Livre",
  desk_reserved: "Reservado",
  desk_canceled: "Cancelado",
  desk_rejected: "Recusado",
  desk_used: "Utilizado",
  desk_unused: "Sem uso",
  desk_using: "Em uso",
  desk_released: "Liberado",
  desk_finished: "Finalizada",
  desk_expired: "Vencida",
  desk_groups: "Grupo de escritórios",
  desk_reserv: "Reservas",
  desk_reports: "Relatórios de uso",
  desk_users: "Usuários",
  desk_layout_button_load: "Baixar estrutura do escritório",
  desk_layout_button_show: "Ver estrutura do escritório",
  desk_layout_button_save: "Salvar",
  desk_layout_button_cancel: "Cancelar",
  desk_layout_button_close: "Fechar",
  desk_layout_button_accept: "Aceito",
  desk_layout_title_load: "Estrutura do escritório",
  desk_layout_download_filename: "estrutura do escritório",
  desk_layout_title_show: "Ver estrutura do escritório",
  desk_layout_label_formats: "*Formatos aceitos: .jpg .png .docx ou pdf",
  desk_layout_label_formats_error:
    "Os formatos permitidos são .png .jpg .docx . pdf",
  desk_layout_label_maxsize_error: "O arquivo deve ter 8MB ou menos",
  desk_layout_label_dragdrop:
    "Arraste aqui o arquivo para carregar automaticamente",
  desk_layout_label_delete_confirm:
    "Você deseja apagar a estrutura do escritório?",
  desk_qr_print_all: "IMPRIMIR TODOS",
  desk_qr_print: "IMPRIMIR QR",
  desk_flyer_title: "CHECK-IN ESCRITÓRIO",
  desk_flyer_description1: "Escanear o QR code da seção",
  desk_flyer_description2: " Meu QR",
  desk_flyer_description3: " do aplicativo",
  desk_flyer_description4: " PassApp.",
  desk_flyer_footer: "Tecnologia de Gestão.",
  add_desks: "Adicionar escritórios",
  edit_desk: "Editar escritório",
  add_desk_group: "Adicionar grupo de escritórios",
  edit_desk_group: "Editar grupo de escritórios",
  assigned_desk: "Escritórios atribuídos",
  confirm_delete_deskGroup: "Remover grupo de escritório?",
  confirm_uncheck_deskGroup: "Remover usuário do grupo?",
  unsetDeskUser: "Cancelar atribuição de usuário",
  confirm_desk_unsetDeskUser: "Cancelar atribuição de usuário?",
  change_list_to_desks: "Ver por escritório",
  change_report_by_desks: "Ver por grupo de escritório",
  report_desk: "Escritório<br/>Atribuído",
  report_desk_excel: "Escritório Atribuído",
  reserv_desk: "Escritório<br/>Reservado",
  reserv_desk_excel: "Escritório Reservado",
  error_getting_desk: "Erro ao obter escritório",
  error_add_desk: "Erro ao adicionar escritório",
  error_edit_desk: "Erro ao editar a escritório",
  error_deleting_desk: "Erro ao excluir a escritório",
  error_getting_desk_group: "Erro ao obter os grupos de escritório",
  error_getting_desk_employees: "Erro ao obter a lista de usuários",
  error_add_desk_group: "Erro ao adicionar o grupo de escritório",
  error_updating_desk_group: "Erro ao editar o grupo de escritório",
  error_deleting_desk_group: "Erro ao adicionar o grupo de escritório",
  error_add_employeeDeskGroup: "Erro ao adicionar o usuário",
  error_remove_employeeDeskGroup: "Erro ao excluir usuário",
  error_getting_reservations_by_desks: "Erro ao obter reservas por escritório",
  day: "Dia",
  month: "mês",
  year: "Ano",
  invalid_birthdate_future: "A data não pode ser posterior a hoje",
  invalid_birthdate: "Data inválida",
  january: "Janeiro",
  february: "Fevereiro",
  march: "Março",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  send_sms: "Enviar SMS",
  activate_account: "Ativação de conta",
  active_by_whatsapp: "Ativar via Whatsapp Web",
  text_active_by_whatsapp:
    "Clique no botão “Ativar via Whatsapp Web” e envie a mensagem que será gerada automaticamente.",
  active_by_sms: "Ativar via SMS",
  text_active_by_sms:
    "Se você não tem Whatsapp você pode ativar a conta via SMS.",
  supplier_data: "Dados do provedor",
  supplier_or_company_name: "Nome do fornecedor ou empresa",
  sector: "Setor",
  comments_for_the_guard: "Comentário para a segurança",
  select_type_of_supplier: "Selecione o tipo de fornecedor",
  error_guest_type: "Deve selecionar um tipo de convidado",
  error_guest_type_provider_corporative:
    "Este complexo não admite avisos de fornecedor a portaria",
  adm_title: "Ativação de conta Administrador de Empresa",
  adm_tyc: "como Administrador de Empresa",
  advice_1: "Dica:",
  advice_2:
    "Se você quiser fazer login com seu e-mail específico do Passapp, ele será vinculado às suas permissões de administrador",
  adm_confirm:
    "Esta ação irá vincular sua conta PassApp a sua conta de administrador de empresa",
  adm_no_user_exist: "O e-mail inserido não existe.",
  adm_register_new_user: "Deseja cadastrar um novo usuário?",
  adm_register:
    "Para criar um usuário Administrador de Empresa preencha os seguintes dados.",
  adm_register_tyc: "para cadastrar minha conta",
  adm_success:
    "Sua conta foi ativada corretamente e seu usuário foi associado ao perfil administrador de sua empresa.",
  adm_login_repeat: "tente novamente",
  adm_login_register: "Criar usuário",
  access_event_menu: "Eventos de acceso",
  access_event_tab: "Eventos de acceso",
  access_event_export_filename: "Eventos de acceso",
  access_event_filter_button: "Filtro",
  access_event_table_incoming: "Ingresante",
  access_event_table_type: "Tipo",
  access_event_table_date: "Fecha",
  access_event_table_company: "Empresa/s",
  access_event_table_host: "Anfitrión",
  access_event_table_manager: "Mánager",
  access_event_table_authorizationType: "Tipo de autorización",
  access_event_table_receptionist: "Equipe de Segurança",
  access_event_table_norows: "Sin resultados",
  menu_my_qr: "Meu QR",
  send_pass_contact_button: "Enviar passe para contatos selecionados",
  send_pass_contact_new_button: "Enviar passe para um novo contato",
  send_pass_contact_search: "Procurar...",
  send_pass_contact_find:
    "{{ amountContact }} usuários do passapp foram sincronizados",
  send_pass_contact_amount_title: "Número de convidados ({{ amountContact }})",
  send_pass_contact_norows: "Ainda não há contatos vinculados",
  send_pass_contact_iphone_setting_1:
    "Para sincronizar os contatos do seu telefone com o PassApp web, você precisa ativar as seguintes configurações. Entre em <b>SETTINGS</b>, vá para <b>SAFARI</b> e siga estas etapas:",
  send_pass_contact_iphone_setting_2: "> Selecione <b>Avançado</b>:",
  send_pass_contact_iphone_setting_3:
    "> Procure a opção <b>Recursos experimentais</b>:",
  send_pass_contact_iphone_setting_4:
    ">  E finalmente ative a função <b>Contact Picker API</b>:",
  send_pass_contact_iphone_setting_cancel: "Cancelar",
  send_pass_contact_iphone_setting_toConfig: "Vá para as configurações",
  my_qr_title: "Meu código QR",
  no_row_passes: "Ainda não há convidados",
  no_row_notifications: "Ainda não há notificações",
  send_pass_contact_sync_button: "Sincronizar novo contato",
  send_pass_share: "Compartilhar convite",
  pass_request_menu: "Solicitações de Passe",
  pass_request_title: "Solicitações de Passe",
  no_row_requests: "Não há pedidos de passes",
  bottom_menu_notification: "Notificações",
  bottom_menu_myguest: "Meus convidados",
  bottom_menu_myqr: "Meu QR",
  bottom_menu_passrequest: "Solicitações",
  pass_title_ocasional: "Ocasional",
  pass_title_permanent: "Permanentes",
  pass_label_no_invited: "Sem convidados",
  pass_label_no_results: "Sem combinações",
  facephoto_dialog_message:
    "Para usar os dispositivos de reconhecimento facial do seu complexo, é necessário fazer o upload de uma foto selfie.",
  facephoto_dialog_cancel: "Carregar selfie",
  facephoto_dialog_confirm: "Lembre-me mais tarde",
  facephoto_menu: "Atualizar selfie",
  facephoto_upload_error: "Ocorreu um erro ao carregar a selfie",
  facephoto_upload_success: "Selfie atualizada com sucesso",
  //charter
  charter_text_dialog_change_month:
    "Se alterar o mês sem confirmar as viagens, perderá as alterações efetuadas",
  charter_button_save_changes: "CONFIRMAR MUDANÇAS",
  charter_button_discard_changes: "Descartar alterações não salvas",
  charter_button_repeat_last_month: "Repetir pedido do mês anterior",
  charter_january: "JANEIRO",
  charter_february: "FEVEREIRO",
  charter_march: "MARÇO",
  charter_april: "ABRIL",
  charter_may: "MAIO",
  charter_june: "JUNHO",
  charter_july: "JULHO",
  charter_august: "AGOSTO",
  charter_september: "SETEMBRO",
  charter_october: "OUTUBRO",
  charter_november: "NOVEMBRO",
  charter_december: "DEZEMBRO",
  monday: "SEGUNDA-FEIRA",
  tuesday: "TERCA-FEIRA",
  wednesday: "CUARTA-FEIRA",
  thursday: "QUINTA-FEIRA",
  friday: "SEXTA-FEIRA",
  saturday: "SABADO",
  sunday: "DOMINGO",
  travels: "VIAGENS",
  charter_user_select_all: "HABILITAR TUDO",
  charter_user_unselect_all: "DESATIVAR TUDO",
  charter_user_dialog_text:
    "Tem certeza de que deseja desabilitar a reserva de fretamento para todos os funcionários?",
  charter_report_travel: "VIAGEN",
  charter_report_date_reserved: "DATA RESERVADA",
  charter_report_user_employee: "USUÁRIO FUNCIONÁRIO",
  charter_report_date_generate: "DATA-HORA DE GERAÇÃO",
  charter_report_status: "DOENÇA",
  charter_report_date_hour: "DATA-HORA DE MODIFICAÇÃO",
  charter_report_available_seats: "ASSENTOS DISPONÍVEIS",
  charter_report_reserved_seats: "ASSENTOS RESERVADOS",
  charter_report_percentage_use: "% DE USO",
  charter_report_view_date: "VER POR DATA",
  charter_report_by_user: "TOTAL RESERVAS YTD",
  charter_report_reserves_used: "Total RESERVAS UTILIZADAS",
  charter_report_percentage_reserves: "% OF USO RESERVAS",
  charter_report_excel_available_seat: "Assentos Disponiveis",
  charter_report_excel_reserved_seat: "Assentos Reservados",
  charter_report_excel_percentage_use: "% de uso",
  charter_report_excel_date_generate: "Data-hora generação",
  charter_reserve_confirm_reservations: "CONFIRMAR RESERVAS",
  charter_reserve_txt_dialog_available:
    "Algumas reservas não puderam ser salvas devido à falta de disponibilidade",
  charter_reserve_txt_dialog_invalid:
    "Não é possível fazer duas reservas para viagens tão próximas uma da outra em seus horários de início",
  charter_reserve_msg_missing_dates: "Nenhuma reservas selecionadas",
  charter_reserve_msg_confirm_dates: "Reservas confirmadas",
  charter_reserve_january: "JANEIRO",
  charter_reserve_february: "FEVEREIRO",
  charter_reserve_march: "MARÇO",
  charter_reserve_april: "ABRIL",
  charter_reserve_may: "MAIO",
  charter_reserve_june: "JUNHO",
  charter_reserve_july: "JULHO",
  charter_reserve_august: "AGOSTO",
  charter_reserve_september: "SETEMBRO",
  charter_reserve_october: "OUTUBRO",
  charter_reserve_november: "NOVEMBRO",
  charter_reserve_december: "DEZEMBRO",
  charter_reserve_error_send: "Erro ao enviar reservas",
  charter_reserve_description_travel: "Descrição da viagem",
  charter_reserve_change_month: "Voltar para a tela anterior",
  charter_reserve_dialog_confirm_reserves: "Confirmar reservas e alterar o mês",
  charter_reserve_dialog_change_mont_txt:
    "Se alterar o mês sem confirmar as reservas, perderá as alterações efetuadas",
  charter_reserve_whitout_reserves:
    "Sua empresa não reservou nenhum slot para este mês para esta viagem. Se você deseja reservar esta viagem, verifique nos próximos meses se há assentos disponíveis e, caso não haja, entre em contato com o administrador da empresa para solicitá los slots para esta viagem.",
  charter_reserve_report_travels_op: "VER POR VIAGENS",
  charter_reserve_color_detail_green: "Com lugar disponível",
  charter_reserve_color_detail_red: "Nenhum lugar disponível",
  charter_reserve_max_reserve_msj:
    "As reservas não podem ser geradas com tanta antecedência nesta viagem",
  charter_travel_tab: "Pedido de viagem",
  charter_reserve_tab: "Reservas",
  charter_usage_report_tab: "Relatório de usos",
  charter_users: "Usuarios",
  charters: "fretamentos",
  charter_reservation: "Reserva de fretamento",
  charter_reserve_use_compliance: "Conformidade de uso",
  charter_reserve_cancel_reservation:
    "Tem certeza que deseja cancelar este reserva?",
  chartar_reserve_of: "",
  charter_reserve_no_reservations: "No reservas geradas",
  charter_reserve_cancel_msj: "Reserva cancelada com sucesso",
  //charter
  //spaces
  space_reservation: "Reserva de Espaços",
  space_reserve_tab: "Reservas",
  space_reserve_date_generation: "DATA-HORA<br/> GERAÇÃO",
  space_reserve_date_resverve: "DATA<br/> RESERVA",
  space_reserve_hour: "TEMPO DE<br/> PARA",
  space_reserve_user_employee: "USUÁRIO<br/> FUNCIONÁRIO",
  space_reserve: "ESPAÇO<br/> RESERVADO",
  space_reserve_status: "ESTADO",
  space_reserve_date_modification: "MODIFICAÇÃO<br/> DATA-HORA",
  space_reserve_credits: "CRÉDITOS",
  space_reserve_money: "DINHEIRO",
  space_reserve_date_generation_excel: "geração de data e hora",
  space_reserve_date_resverve_excel: "data de reserva",
  space_reserve_hour_excel: "tempo de para",
  space_reserve_user_employee_excel: "usuário funcionário",
  space_reserve_excel: "espaço reservado",
  space_reserve_date_modification_excel: "Modificação de data e hora",
  space_reserve_credits_excel: "créditos",
  space_reserve_money_excel: "dinheiro",
  //spaces
  passes_mail_input: "Digite o e-mail para ativar sua conta passapp",
  passes_mail_continue: "Continuar",
  passes_mail_repeat_msj:
    "O e-mail inserido já existe no passapp, por favor tente inserir outro",
  passes_mail_active_msj:
    "Ative sua conta acessando o link enviado para sua caixa de e-mail",
  passes_mail_resend_title: "Reenviar email",
  passes_mail_change_msj: "Mude o endereço de email",
  passes_mail_entry_msj:
    "Para ativar sua conta passapp, você deve entrar no seguinte link.",
  passes_mail_hello: "Olá",
  passes_mail_success_send: "E-mail enviado com sucesso",
  space_credit_balance: "Saldo em créditos",
  space_credit_balance_tab: "Saldo por mês",
  space_credit_balance_year_month: "mês e ano",
  space_credit_money_excel: "moeda",
  space_balance_money_excel: "saldo",
  space_balance_balance_name: "CRÉDITOS",
};
export default pt;
