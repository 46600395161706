import { generatePassAppHash } from "../utils/md5Hash";
import { createInviteDynamicLink, getBrowserLanguage } from "utils/Utils";
export default {
  request({ url, data, method, contentType, baseUrl, noHeaders }) {
    if (!noHeaders) {
      if (!contentType) contentType = "application/json";
      if (data && contentType === "application/json") {
        if (!baseUrl || !baseUrl.includes("firebase")) {
          data = {
            ...data,
            APP_LANG: getBrowserLanguage(),
          };
        }
        data = JSON.stringify(data);
      }
    }
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl ? baseUrl : process.env.REACT_APP_BASE_URL}${url}`, {
        method: method || "get",
        body: data || null,
      })
        .then((response) => {
          response
            .json()
            .then((json) => {
              if (json.status === "error") {
                reject(json.msg);
              } else {
                if (json.status === "ok") resolve(json.data);
                else resolve(json);
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err.msg);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getUrlBase() {
    return process.env.REACT_APP_BASE_URL;
  },
  login(email, password) {
    return this.request({
      url: `ws/login`,
      data: {
        email: email,
        password: password,
      },
      method: "post",
    });
  },
  register(credentials) {
    return this.request({
      url: `ws/register`,
      data: {
        email: credentials.email,
        password: credentials.password,
        phone: credentials.phone,
      },
      method: "post",
    });
  },
  getInvitesAsHost(token) {
    return this.request({
      url: `ws/getInvitesAsHost`,
      data: {
        token: token,
      },
      method: "post",
    });
  },
  getGlobalOptions() {
    const timestamp = Date.now() / 1000;
    const hash = generatePassAppHash(timestamp.toString());
    return this.request({
      url: `ws/globalOptions`,
      data: {
        timestamp,
        hash,
      },
      method: "post",
    });
  },
  getFriends(token) {
    return this.request({
      url: `ws/getFriends`,
      data: {
        token: token,
      },
      method: "post",
    });
  },
  importContacts(token, phoneNumbers) {
    return this.request({
      url: `ws/importContacts`,
      data: {
        token,
        phoneNumbers,
      },
      method: "post",
    });
  },
  getUser(token) {
    return this.request({
      url: `ws/getUser`,
      data: {
        token: token,
      },
      method: "post",
    });
  },
  invite(token, pass) {
    return this.request({
      url: `ws/invite`,
      data: {
        ...pass,
        token: token,
      },
      method: "post",
    });
  },
  updateInvite(token, pass) {
    return this.request({
      url: `ws/updateInvite`,
      data: {
        ...pass,
        token: token,
      },
      method: "post",
    });
  },
  getNotifications(token) {
    return this.request({
      url: `ws/getNotifications`,
      data: {
        token: token,
        limit: 50,
      },
      method: "post",
    });
  },
  recoverPassword(email) {
    const hash = generatePassAppHash(email.toString());
    return this.request({
      url: `ws/passwordRecover`,
      data: {
        email,
        hash,
      },
      method: "post",
    });
  },
  generateDynamicLink(pass, user, establishment) {
    let link = createInviteDynamicLink(pass, user, establishment);
    return this.request({
      url: ``,
      data: {
        longDynamicLink: link,
        suffix: {
          option: "SHORT",
        },
      },
      method: "post",
      baseUrl:
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAD8UPmaaRNYuTA_nIHJMaU5oUQ5xOtKG0",
    });
  },
  confirmPassword(email, fields) {
    return new Promise((resolve, reject) => {
      this.login(email, fields.oldPassword)
        .then((res) => {
          this.request({
            url: `ws/updateUser`,
            data: {
              token: res.token,
              ...fields,
            },
            method: "post",
          })
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAuthorizations(token) {
    return this.request({
      url: `ws/getAuthorizations`,
      data: {
        token: token,
      },
      method: "post",
    });
  },
  addAuthorization(token, authorization) {
    return this.request({
      url: `ws/addAuthorization`,
      data: {
        token: token,
        ...authorization,
      },
      method: "post",
    });
  },
  revokeAuthorization(token, id, action) {
    return this.request({
      url: `ws/revokeAuthorization`,
      data: {
        token: token,
        id,
        action,
      },
      method: "post",
    });
  },
  requestVerificationCode(token, phone, type) {
    return this.request({
      url: `ws/send2FA`,
      data: {
        token: token,
        phone: phone,
        type: type,
      },
      method: "post",
    });
  },
  verifyVerificationCode(token, phone, code) {
    return this.request({
      url: `ws/verify2FA`,
      data: {
        token: token,
        phone: phone,
        code: code,
      },
      method: "post",
    });
  },
  updateUser(token, data) {
    return this.request({
      url: `ws/updateUser`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  getTokenFile(token, data) {
    return this.request({
      url: `ws/getFileAccessToken`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  workplaces(token, data) {
    return this.request({
      url: `ws/workplaces`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  uploadImage(token, image, type) {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("file", image);
    formData.append("fileType", type);
    return this.request({
      url: `ws/upload`,
      data: formData,
      method: "post",
      noHeaders: true,
    });
  },
  getPassManager(token, data) {
    return this.request({
      url: `ws/company-manager/getPassManagerList`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  updateStatus(token, data) {
    return this.request({
      url: `ws/company-manager/setWorkplaceInvite`,
      data: {
        token: token,
        id: data.id,
        action: data.status,
      },
      method: "post",
    });
  },
  toggleStatus(token, data) {
    let action = "addParking";
    /* If already has FreeParking => remove this */
    if (data.currentFreeParking) {
      action = "removeParking";
    }
    return this.request({
      url: `ws/company-manager/setWorkplaceInvite`,
      data: {
        token: token,
        id: data.id,
        action: action,
      },
      method: "post",
    });
  },
  getCompanyStaff(token, company, data) {
    return this.request({
      url: `ws/company-manager/getCompanyStaffList`,
      data: {
        token: token,
        company: company,
        ...data,
      },
      method: "post",
    });
  },
  getCompanyStaffRequests(token, data) {
    return this.request({
      url: `ws/company-manager/getCompanyStaffRequests`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  getCompanyStaffUnassigned(token, data) {
    return this.request({
      url: `ws/company-manager/getCompanyStaffUnassigned`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  updateCompanyStaffStatus(token, data) {
    return this.request({
      url: `ws/company-manager/setCompanyStaff`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  saveCompanyStaff(token, data) {
    return this.request({
      url: `ws/company-manager/saveCompanyStaff`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  checkPassManagerEmployees(token, data) {
    return this.request({
      url: `ws/company-manager/checkPassManagerEmployees`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  checkStaffManagerEmployees(token, data) {
    return this.request({
      url: `ws/company-manager/checkStaffManagerEmployees`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  getCompanyManagers(token, data) {
    return this.request({
      url: `ws/company-manager/getCompanyManagers`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  getSharedFacilitiesReservations(token, data) {
    return this.request({
      url: `ws/company-manager/getSharedFacilitiesReservations`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  emptySharedFacilityReservation(token, data) {
    return this.request({
      url: `ws/company-manager/emptySharedFacilityReservation`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  setSharedFacilityReservation(token, data) {
    return this.request({
      url: `ws/company-manager/setSharedFacilityReservation`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  getRoomReservationSettings(token, data) {
    return this.request({
      url: `ws/company-manager/getSharedFacilitiesSettings`,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  sendPostFiles(token, id, files) {
    let formData = new FormData();
    formData.append("file", files);
    formData.append("id", id);
    formData.append("token", token);
    return this.request({
      url: `ws/company-manager/uploadSharedFacilityReservation`,
      data: formData,
      method: "post",
      noHeaders: true,
      /*contentType: "multipart/form-data"*/
    });
  },
  sendOthersFiles(token, id, files) {
    let formData = new FormData();
    formData.append("file", files);
    formData.append("id", id);
    formData.append("token", token);
    return this.request({
      url: `ws/company-manager/uploadSharedFacilityReservation`,
      data: formData,
      method: "post",
      noHeaders: true,
    });
  },
  sendRoomReservation(token, data, type) {
    let endpoint = `ws/company-manager/addSharedFacilityReservation`;
    if (type === "upd") {
      endpoint = `ws/company-manager/editSharedFacilityReservation`;
    }
    return this.request({
      url: endpoint,
      data: {
        token: token,
        ...data,
      },
      method: "post",
    });
  },
  parkingManagerSend_POST(endpoint, token, company, data) {
    return this.request({
      url: `ws/parking-manager/` + endpoint,
      data: {
        token: token,
        company: company,
        ...data,
      },
      method: "post",
    });
  },
  deskManagerSend_POST(endpoint, token, company, data) {
    return this.request({
      url: `ws/desk-manager/` + endpoint,
      data: {
        token: token,
        company: company,
        ...data,
      },
      method: "post",
    });
  },
  getActivationCode(token) {
    return this.request({
      url: `ws/getActivationCode`,
      data: {
        token: token,
      },
      method: "post",
    });
  },
  checkActivationToken(activationToken) {
    return this.request({
      url: `ws/company-manager/checkActivationToken`,
      data: {
        activationToken,
      },
      method: "post",
    });
  },
  associate(token, associationToken, id) {
    return this.request({
      url: `ws/company-manager/associate`,
      data: {
        token,
        associationToken,
        id,
      },
      method: "post",
    });
  },
  uploadDeskPlan(token, company, file) {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("company", company);
    formData.append("file", file);
    return this.request({
      url: `/ws/desk-manager/uploadDeskPlan`,
      data: formData,
      method: "post",
      noHeaders: true,
    });
  },
  getDeskPlan(token, company) {
    return this.request({
      url: `/ws/desk-manager/getDeskPlan`,
      data: {
        token: token,
        company: company,
      },
      method: "post",
    });
  },
  deleteDeskPlan(token, company) {
    return this.request({
      url: `/ws/desk-manager/deleteDeskPlan`,
      data: {
        token: token,
        company: company,
      },
      method: "post",
    });
  },
  getEventsList(token, from, to) {
    return this.request({
      url: `/ws/company-manager/getEventsList`,
      data: {
        token,
        from,
        to,
      },
      method: "post",
    });
  },
  getCustomization(token) {
    return new Promise((resolve) => {
      const tokenTest =
        "6037e4fc9e144f7286674654:345b8a9f0c2e0c4213373ad725e267d2";
      const customization = {
        palette: {
          primary: {
            main: "#9c27b0",
            dark: "#6a1b9a",
          },
        },
      };
      setTimeout(() => {
        if (token === tokenTest) resolve(customization);
        else resolve(null);
      }, 1200);
    });
  },
  getUserTokenApiKey(id, apikey) {
    return this.request({
      url: `ws-apikey/getUserToken`,
      data: {
        id,
        apikey,
      },
      method: "post",
    });
  },
  checkExternalUserToken(tokenTemp) {
    return this.request({
      url: `ws/checkExternalUserToken`,
      data: {
        token: tokenTemp,
      },
      method: "post",
    });
  },
  getChartersAvailability(token, company) {
    return this.request({
      url: `/ws/charter-user/getCharterAvailability`,
      data: {
        token,
        company,
      },
      method: "post",
    });
  },
  getCharterReservations(token, company) {
    return this.request({
      url: `/ws/charter-user/getCharterReservations`,
      data: {
        token,
        company,
      },
      method: "post",
    });
  },
  setCharterReservations(token, company, reservations) {
    return this.request({
      url: `/ws/charter-user/setCharterReservations`,
      data: {
        token,
        company,
        reservations,
      },
      method: "post",
    });
  },
  getCharterRequests(token, company, period) {
    return this.request({
      url: `/ws/charter-manager/getCharterRequests`,
      data: {
        token,
        company,
        period,
      },
      method: "post",
    });
  },
  setCharterRequests(token, company, period, charters) {
    return this.request({
      url: `/ws/charter-manager/setCharterRequests`,
      data: {
        token,
        company,
        period,
        charters,
      },
      method: "post",
    });
  },
  getCharterEmployees(token, company) {
    return this.request({
      url: `ws/charter-manager/getCharterEmployees`,
      data: {
        token,
        company,
      },
      method: "post",
    });
  },
  setCharterEmployee(token, company, user) {
    return this.request({
      url: `/ws/charter-manager/setCharterEmployee`,
      data: {
        token,
        company,
        user,
      },
      method: "post",
    });
  },
  unsetCharterEmployee(token, company, user) {
    return this.request({
      url: `/ws/charter-manager/unsetCharterEmployee`,
      data: {
        token,
        company,
        user,
      },
      method: "post",
    });
  },
  setCharterAllEmployees(token, company) {
    return this.request({
      url: `/ws/charter-manager/setCharterAllEmployees`,
      data: {
        token,
        company,
      },
      method: "post",
    });
  },
  unsetCharterAllEmployees(token, company) {
    return this.request({
      url: `/ws/charter-manager/unsetCharterAllEmployees`,
      data: {
        token,
        company,
      },
      method: "post",
    });
  },
  getCharterReservationsByDetail(token, company, from, to, status) {
    return this.request({
      url: `/ws/charter-manager/getCharterReservationsByDetail`,
      data: {
        token,
        company,
        from,
        to,
        status,
      },
      method: "post",
    });
  },
  getCharterReservationsByCharter(token, company, date) {
    return this.request({
      url: `/ws/charter-manager/getCharterReservationsByCharter`,
      data: {
        token,
        company,
        date,
      },
      method: "post",
    });
  },
  getCharterReportByCharter(token, company, from, to) {
    return this.request({
      url: `/ws/charter-manager/getCharterReportByCharter`,
      data: {
        token,
        company,
        from,
        to,
      },
      method: "post",
    });
  },
  getCharterReportByUsers(token, company, from, to) {
    return this.request({
      url: `/ws/charter-manager/getCharterReportByUsers`,
      data: {
        token,
        company,
        from,
        to,
      },
      method: "post",
    });
  },
  getSpaceReservations(token, company, from, to, status) {
    return this.request({
      url: `/ws/space-manager/getSpaceReservations`,
      data: {
        token,
        company,
        from,
        to,
        status,
      },
      method: "post",
    });
  },
  getSpaceCreditUsers(token, company) {
    return this.request({
      url: `/ws/space-manager/getSpaceCreditUsers`,
      data: {
        token,
        company,
      },
      method: "post",
    });
  },
  setSpaceCreditUsers(token, company, users, check) {
    return this.request({
      url: `/ws/space-manager/setSpaceCreditUsers`,
      data: {
        token,
        company,
        users,
        check,
      },
      method: "post",
    });
  },
  updateEmailValidation(token, email) {
    return this.request({
      url: `/ws/updateEmail`,
      data: {
        token,
        email,
      },
      method: "post",
    });
  },
  getRemainingSpaceCredit(token, company, year) {
    return this.request({
      url: `/ws/space-manager/getRemainingSpaceCredit`,
      data: {
        token,
        company,
        year,
      },
      method: "post",
    });
  },
};
