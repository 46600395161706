const es = {
  app_name: "PassApp Web",
  error: "Error",
  email: "Email",
  password: "Contraseña",
  repeat_password: "Repetir contraseña",
  password_validation_length: "Longitud de contraseña: como mínimo 8 caracteres.",
  password_validation_numbers: "Caracteres numéricos: como mínimo 1 número.",
  password_validation_symbols: "Caracteres especiales: como mínimo un 1 carácter especial.",
  password_validation_lower: "Letras minúsculas: como mínimo 1 letra minúscula.",
  password_validation_upper: "Letras mayúsculas: como mínimo 1 letra mayúscula.",
  login: "Ingresar",
  invalid_login: "El usuario o contraseña ingresado no es válido",
  my_passes: "Mis Pases",
  my_guests: "Mis invitados",
  my_notifications: "Mis notificaciones",
  validate_passes: "Validar Pases",
  video_tutorials: "Preguntas Frecuentes",
  permanent_passes: "PERMANENTES",
  access_prevalidation_status_success: "Datos prevalidados",
  access_prevalidation_status_error: "Requiere prevalidación",
  occasional_passes: "OCASIONALES",
  error_getting_passes: "Error obteniendo los pases",
  invited: "Invitado",
  no_invited: "Sin invitados adheridos aún",
  pass_status_accepted: "Aceptado",
  pass_status_used: "Usado",
  pass_status_pending: "Solicitud pendiente de aprobación",
  pass_status_pending_approval: "Pase pendiente de aprobación",
  auth_status_pending_approval: "Aviso pendiente de aprobación",
  pass_status_accepted_label: "Pase aceptado",
  pass_status_used_label: "Pase usado",
  pass_status_pending_label: "Pase pendiente",
  pass_status_rejected_label: "Pase rechazado",
  pass_status_cancelled_label: "Pase cancelado",
  pass_status_finished_label: "Pase finalizado",
  pass_status_rejected: "Rechazado",
  pass_status_cancelled: "Cancelado",
  pass_status_finished: "Finalizado",
  new_invitation: "Nueva invitación",
  share: "Compartir",
  share_link_copied: "Link para compartir copiado",
  copy_link: "Copiar link de invitación",
  choose_destination: "Seleccionar destino",
  authorized_until: "Autorizado hasta",
  authorized_from: "Autorizado desde",
  permanent: "Permanente",
  occasional: "Ocasional",
  particular: "Particular",
  provider: "Proveedor",
  comment: "Comentario",
  send_invite: "Enviar invitación",
  pass_type: "Tipo de Pase",
  guest_type: "Tipo de invitado",
  day_letter_monday: "L",
  day_letter_tuesday: "M",
  day_letter_wednesday: "M",
  day_letter_thursday: "J",
  day_letter_friday: "V",
  day_letter_saturday: "S",
  day_letter_sunday: "D",
  days: "Días",
  allowed_times_label: "Horarios permitidos de ingreso",
  all_day: "Todo el día",
  entry_time_label: "Entre las",
  out_time_label: "y las",
  unselected: "Sin seleccionar",
  must_select_destination: "Debe seleccionar una empresa o complejo",
  invalid_dni: "El documento solo puede tener letras y números",
  error_pass_length:
    "El pase no puede tener un rango de duración mayor a 12 horas",
  error_auth_length:
    "La autorización no puede tener un rango de duración mayor a 12 horas",
  error_end_before_start:
    "La fecha de finalización debe ser posterior a la de comienzo",
  error_select_day: "Debe seleccionar al menos un día",
  error_out_before_entry:
    "El horario de entrada debe ser anterior al de salida",
  error_max_permanent_passes_user:
    "Tu usuario ha alcanzado el máximo de pases permanentes",
  error_max_permanent_passes: "Se ha alcanzado el máximo de pases permanentes",
  update_invite: "Actualizar Pase",
  update: "Actualizar",
  cancel_authorization: "Cancelar invitación",
  cancel_invite: "Cancelar Pase",
  pass_updated_successfully: "Pase actualizado correctamente",
  search_passes: "Buscar Pases",
  notifications: "Notificaciones",
  accept: "Aceptar",
  pass_created: "Pase creado correctamente",
  pass_link_created:
    "Debe copiar y enviar el siguiente link al invitado para que complete el proceso de registro:",
  decline: "Rechazar",
  pass_request: "Solicitud de pase",
  no_comments: "Sin comentarios",
  invite_accepted: "Invitación aceptada correctamente",
  invite_rejected: "Invitación rechazada correctamente",
  invite_detail: "Detalle de invitación",
  invite_accept_error: "Error aceptando invitación",
  invite_reject_error: "Error rechazando invitación",
  invite_update_error: "Error actualizando invitación",
  invite_create_error: "Error creando invitación",
  invite_cancel_error: "Error cancelando invitación",
  dynamic_link_error: "Error al crear el link del pase",
  invite_cancel: "Invitacion cancelada correctamente",
  logout: "Cerrar sesión",
  error_getting_user_info: "Error obteniendo informacion del usuario",
  error_getting_notifications: "Error obteniendo las notificaciones",
  tutorials: "Tutoriales",
  i_am_visitor: "Soy visita",
  i_want_to_invite: "Quiero invitar",
  link_copied: "Link copiado",
  forgot_password: "¿Olvidaste tu contraseña?",
  recover_password: "Recuperar contraseña",
  password_changed: "Contraseña cambiada correctamente",
  enter_email: "Ingresa tu email",
  temp_password: "Contraseña temporal",
  repeat_mail: "Repetir email",
  new_password: "Nueva contraseña",
  confirm_password: "Confirmar contraseña",
  error_sending_email: "Error enviando email de invitación",
  recover_password_email_sent:
    "Hemos enviado una contraseña temporal a tu correo para que puedas elegir una nueva contraseña",
  error_updating_user: "Error actualizando los datos del usuario",
  passwords_dont_match: "Las contraseñas no coinciden",
  email_dont_match: "El Email y su confirmación no coinciden",
  send_pass: "Enviar Pase al invitado",
  notify_security: "Avisar a portería",
  error_getting_authorizations:
    "Error obteniendo las autorizaciones del usuario",
  authorization_expired: "Vencido",
  revoked: "Revocada",
  name: "Nombre",
  lastName: "Apellido",
  dni_number: "Número de documento",
  dni_country: "País del documento",
  admin_comment: "Comentarios del administrador",
  guest_company: "Empresa del invitado",
  guest_email: "Email del invitado",
  assign_parking: "Asignar cochera de cortesia a invitados",
  send_to_security: "Enviar a porteria",
  search_country: "Buscar país..",
  error_adding_authorization: "Error creando autorización",
  error_updating_authorization: "Error actualizando autorización",
  error_cancelling_authorization: "Error cancelando autorización",
  authorization_added: "Autorización creada correctamente",
  authorization_updated: "Autorización actualizada correctamente",
  authorization_cancelled: "Autorización cancelada correctamente",
  guest_amount: "Cantidad de invitados",
  cancel: "Cancelar",
  yes: "Si",
  no: "No",
  confirm_text: "¿Está seguro que desea realizar esta acción?",
  confirm: "Confirmar",
  delete_user: "¿Desea eliminar a {{user}} de esta invitación?",
  confirm_cancel: "¿Está seguro que desea salir sin guardar cambios?",
  confirm_cancel_pass: "¿Está seguro que desea cancelar esta invitación?",
  must_join_establishment:
    "Debe adherirse a algún establecimiento para poder enviar pases",
  global_options_error: "Error obteniendo las opciones globales",
  friends_error: "Error obteniendo la lista de amigos",
  create_new_user: "Crear usuario nuevo",
  create_user_info: "Para crear un usuario completa los siguientes datos",
  create_user_info_single: "Para activar tu usuario completa el siguiente dato",
  mail: "Mail",
  number_example: "Ejemplo: +54 11 1234 5678",
  number_warning: "Se le enviará un SMS para validar su usuario.",
  accept_terms: "Acepto los {{terms}}",
  terms: "Términos y Condiciones",
  create_user: "Crear usuario",
  register_error: "Error al intentar crear el usuario",
  two_factor_info:
    "Por favor ingresá el código de verificación que enviamos a {{phone}}",
  wish_to_change: "¿Desea cambiar el número ingresado?",
  must_accept_terms: "Debe aceptar los terminos y condiciones",
  send: "Enviar",
  resend_sms_code: "Reenviar código por SMS",
  receive_code_by_call: "Recibir código por llamado telefónico",
  cancel_verification: "Cancelar verificación",
  enter_phone: "Ingrese nuevo número de teléfono",
  change_phone_number: "Cambiar número de teléfono",
  confirm_verification_cancel:
    "¿Realmente quieres cancelar la verificacion de tu cuenta? No podras volver a este paso",
  error_requesting_verification: "Error al enviar el código",
  code_sent_successfully: "Código de verificación enviado correctamente",
  add_profile_photo: "Agregue una foto para su perfil",
  countries_and_buildings: "Barrios y edificios",
  companies: "Empresas",
  join_company: "Asociarme a una empresa",
  join_country: "Asociarme a un complejo",
  join_building: "Asociarme a un edificio",
  floor_number: "Número de piso o departamento",
  personal_number: "Teléfono particular",
  lot_number: "Número de lote",
  map_location: "Ubicá tu casa",
  map_location_info:
    "Hacé click dentro del mapa para indicar la ubicación exacta de tu casa",
  join: "Asociarme",
  error_choose_location: "Debe ubicar su casa en el mapa",
  error_no_particular_phone: "Debe ingresar su teléfono particular",
  error_no_lot_number: "Debe ingresar el número de lote",
  error_no_floor_number: "Debe ingresar el número de piso o departamento",
  error_joining_establishment: "Error al intentar asociarse",
  manager: "Administrador de empresa",
  company_location: "Ubicación de la empresa",
  company_details:
    "Otros detalles de ubicación que figurarán en tus invitaciones (ej, piso, oficina)",
  birth_date: "Fecha de nacimiento",
  user_updated: "Usuario actualizado correctamente",
  invalid_verification_code: "Código de verificación inválido",
  capture: "Sacar foto",
  upload_photo: "Subir manualmente",
  capture_again: "Sacar otra foto",
  continue: "Continuar",
  register_joined_country_success:
    "Luego de completar sus datos, su solicitud de adhesión será enviada al administrador del complejo, y quedará pendiente de autorización.",
  joined_country_success:
    "Su solicitud de adhesión fue enviada al administrador del complejo, y queda pendiente de autorización.",
  joined_company_simplified:
    "Te enviamos un mail a tu casilla de correo para validar tu dirección de mail.",
  register_joined_company_simplified:
    "Luego de completar sus datos, Te enviaremos un mail a tu casilla de correo para validar tu dirección de mail.",
  my_establishments: "Mis complejos asociados",
  join_establishment: "Asociarme a un complejo",
  pending: "Pendiente",
  updated_country: "Complejo actualizado correctamente",
  must_select_company: "Debe seleccionar una empresa",
  must_select_manager: "Debe seleccionar un administrador de empresa",
  search_establishment: "Buscar un complejo para asociarse",
  search_pass: "Buscar un Pase",
  error_uploading_image: "Error subiendo imagen",
  max_guests_allowed: "Cantidad de invitados admitidos",
  please_select_max_guests:
    "Debe indicar la cantidad de invitados que admitirá el pase",
  dialog_max_guests_allowed:
    "Indique la cantidad de invitados que admitirá este pase (considere los invitados que ya estan asociados)",
  dialog_max_guests_error:
    "Indique una cantidad de invitados mayor para poder compartir el pase",
  please_specify_birthdate: "Debe indicar su fecha de nacimiento",
  rows_per_page: "Filas por página",
  all: "Todos",
  pass_mamager_confirm_status_accepted:
    "Realmente desea enviar el pase como Aceptado?",
  pass_mamager_confirm_status_rejected:
    "Realmente desea enviar el pase como Rechazado?",
  pass_mamager_confirm_status_canceled:
    "Realmente desea enviar el pase como Cancelado?",
  registers: "Registros",
  host: "Anfitrión",
  type: "Tipo",
  guests: "Invitado",
  date: "Fecha",
  hour: "Hora",
  status: "Estado",
  actions: "Acciones",
  error_getting_PassManagerList: "Error al obtener el listado de Pases",
  pass_status_error_current: "El Pase ya se encuentra en el estado solitado",
  pass_status_error_notAllowed: "No se pudo cambiar el estado del Pase",
  pass_status_changed: "Estado del Pase cambiado con éxito",
  error_update_PassManager: "Error al enviar el estado del Pase",
  undefined: "objeto no encontrado",
  pass_status_error_notAccepted: "El pase debe estar aceptado",
  pass_parking_changed: "Parking cambiado",
  accepted: "Aceptado",
  finished: "Finalizado",
  canceled: "Cancelado",
  rejected: "Rechazado",
  reject: "Rechazar",
  show_comment: "Comentarios",
  company_staff: "Administrar personal",
  company_staff_confirm_status_remove: "¿Desea desvincular al empleado?",
  company_staff_removed: "Empleado desvinculado",
  company_staff_confirm_status_approve: "¿Aprobar solicitud?",
  company_staff_approved: "Solicitud aceptada",
  company_staff_confirm_status_reject: "¿Rechazar solicitud?",
  company_staff_rejected: "Solicitud rechazada",
  company_staff_confirm_status_claim: "¿Desea ser su manager?",
  company_staff_your_namager: "Soy su manager",
  company_staff_remove: "Desvincular",
  see_personal: "Ver ficha personal",
  approve: "Aprobar",
  actived_person: "Personal activo",
  new_requests: "Nuevas solicitudes",
  unmanaged_staff: "Personal sin manager",
  passapp_user_id: "ID de usuario PassApp",
  association_date: "Fecha de asociación",
  photo: "Foto",
  dni: "DNI",
  phone: "Teléfono",
  place: "Lugar",
  user_detail: "Ficha de usuario",
  special_permissions: "Permisos especiales",
  user_manager: "Admin de personal",
  passes_validator: "Validador de Pases",
  access_permissions: "Permisos de accesos",
  room_reservation: "Reserva de auditorios",
  parking_manager: "Admin de cocheras",
  desk_manager: "Admin de escritorios",
  super_range: "Autoriza visitas",
  face_photo: "Foto de portada",
  backDNI_photo: "Foto de DNI (detrás)",
  frontDNI_photo: "Foto de DNI (frente)",
  error_getting_CompanyStaff: "Error al obtener el listado de personal",
  error_saving_CompanyStaff: "Error al guardar el empleado",
  company_staff_personal_not_available: "Ficha no disponible",
  company_staff_personal_updated: "Ficha actualizada",
  select_a_pass_validator1: "Seleccione al nuevo validador de pases",
  select_a_pass_validator2: "que reemplazará el rol de este usuario",
  select_a_staff_manager1: "Seleccione al nuevo administrador de personal",
  select_a_staff_manager2: "que reemplazará el rol de este usuario",
  error_getting_RoomReservation: "Error al obtener el listado de Reservas",
  error_cancel_RoomReservation: "Error al cancelar la Reserva",
  error_get_simple_RoomReservation: "Error al obtener la Reserva",
  error_save_RoomReservation: "Error al enviar la Reserva",
  error_upload_file_RoomReservation: "Error al enviar el archivo de la Reserva",
  save: "Guardar",
  search: "Buscar",
  no_result: "No se encontraron registros",
  date_time: "Fecha y Hora",
  instals: "Instalaciones",
  documents: "Documentos",
  admin_notes: "Comentarios Admin",
  edit: "Editar",
  purchase_order: "Orden de compra",
  no_purchase_order: "Sin orden de compra",
  approved: "Aprobado",
  all_results: "Todos los resultados",
  room_reseration: "Reserva de auditorios",
  download: "Descargar Archivo",
  see_file: "Ver Archivo",
  chairs: "SILLAS",
  categingTablesInside: "MESAS COFFEE DENTRO",
  categingTablesOutside: "MESAS COFFEE AFUERA",
  speakerTables: "MESAS ORADOR",
  assistanceTables: "MESAS REACT. ARMADO",
  tableCloths: "MANTELES P/ARMADO",
  staticScreens: "PANTALLAS FIJAS",
  mobileScreens: "PANTALLAS MÓVILES",
  mobileProjectors: "PROYECTORES MÓVILES",
  microphone: "MICRÓFONOS",
  multipleExtensions: "EXTENSIONES MÚLTIPLES",
  error_TandC: "Debe aceptar los términos y condiciones",
  error_sharedFacilities: "Debe cargar al menos una instalación compartida",
  error_date: "Debe cargar la fecha de la reserva",
  error_beginTime: "Debe cargar la Hora de inicio de la reserva",
  error_endTime: "Debe cargar la Hora de fin de la reserva",
  error_time_range: "Debe cargar un horario entre las 08:00 y 18:00",
  error_time_range_2days:
    "Las reservas deben realizarse con al menos 48hs de antelación",
  error_time_inverted:
    "El horario de fin no puede ser menor al horario de inicio",
  error_mobiliario:
    "Por favor, complete todos los campos de la sección mobiliario con la cantidad deseada (puede ser cero)",
  error_layout: "Armado no disponible",
  room_not_avail: "Auditorio no disponible",
  delete_file: "Eliminar archivo adjunto",
  reservation_owner: "Reserva cargada por:",
  room_reserv_step1: "INDICÁ DETALLES DE TU RESERVA",
  room_reserv_step2: "INDICÁ DETALLES DEL MOBILIARIO",
  room_reserv_step3: "ADJUNTÁ TU ORDEN DE COMPRA",
  room_reserv_step4: "ADJUNTÁ OTROS DOCUMENTOS",
  room_reserv_step5: "CARGÁ TUS OBSERVACIONES",
  beginTime: "Horario Inicio",
  endTime: "Horario Fin",
  layout: "Armado",
  sharedFacilities: "Instalaciones compartidas",
  mobiliario: "Mobiliario",
  sinMobiliario: "Sin mobiliario",
  notes: "Observaciones",
  without_notes: "Sin observaciones",
  room_reserv_current: "ESTA RESERVA",
  room_reserv_approved: "OCUPADA",
  room_reserv_pending: "PENDIENTE DE APROBACIÓN",
  text_TC_1: "He leído y acepto los",
  text_TC_2: "Términos y condiciones",
  layout_information_text_see: "Ver planos de armado",
  layout_information_text: "Planos de armado",
  dimension_information_text_see: "Ver dimensiones de auditorios",
  dimension_information_text: "Dimensiones de auditorios",
  loaded: "Cargada",
  check_data_before_to_continue: "Verifique los datos para continuar",
  text_without_purchase_order:
    "La reserva no contiene orden de compra. Esta reserva caerá en 48hs si no se adjunta antes una OC.",
  from: "de",
  to: "a",
  updated_successfully_RoomReservation: "La reserva fue actualizada con éxito",
  added_successfully_RoomReservation:
    "La solicitud de reserva fue enviada al administrador de auditorios para su aprobación.",
  textDropzoneFile: "Arrastre y suelte aquí los archivos...",
  textDropzoneFileInfo1: "10MB máximo",
  textDropzoneFileInfo2: "Archivos soportados: pdf, jpg, png, txt",
  textDropzoneErrorFileSize: "El archivo no puede superar los 10MB",
  textDropzoneRejectedFileType:
    "Los archivos soportados son: pdf, jpg, png, txt",
  invalid_date_format: "Fecha inválida",
  invalid_time_format: "Hora inválida",
  text_other_files_RoomReservation:
    "Recuerde que los certificados de ART deben estar acompañados de la cláusula de no repetición a nombre de OPM INMOBILIARIA S.A. Cuit N 30-68583774-5, sin excepciones.",
  assigned_user: "Usuario asignado",
  assigned_users: "Usuarios asignados",
  assigned_parking: "Cocheras Asignadas",
  unsetParkingLotUser: "Cancelar asignación de usuario",
  confirm_parkingLot_unsetParkingLotUser: "¿Desasignar usuario?",
  confirm_parkingLot_remove: "¿Remover cochera con {{countParkings}} reservas?",
  confirm_parkingGroup_remove:
    "¿Remover grupo de cocheras con {{countParkings}} reservas?",
  confirm_delete_parkingGroup: "¿Remover grupo de cocheras?",
  confirm_uncheck_parkingGroup: "¿Remover usuario del grupo?",
  error_getting_parking: "Error al obtener las cocheras",
  error_add_parkingLot: "Error al agregar las cocheras",
  error_edit_parkingLot: "Error al editar la cochera",
  error_deleting_parking: "Error al borrar la cochera",
  error_getting_parking_group: "Error al obtener los grupos de cocheras",
  error_getting_parking_employees: "Error al obtener el listado de usuarios",
  error_add_parking_group: "Error al agregar el grupo de cocheras",
  error_updating_parking_group: "Error al editar el grupo de cocheras",
  error_deleting_parking_group: "Error al borrar el grupo de cocheras",
  error_add_employeeParkingGroup: "Error al agregar el usuario",
  error_remove_employeeParkingGroup: "Error al borrar el usuario",
  error_getting_reservations_by_parkingLots:
    "Error al obtener reservas por cochera",
  error_getting_reservations_by_details:
    "Error al obtener reservas por detalle",
  addNewName: "Agregar nueva cochera",
  add_parking_lots: "Agregar cocheras",
  edit_parking_lot: "Editar cochera",
  add_parking_group: "Agregar grupo de cocheras",
  edit_parking_group: "Editar grupo de cocheras",
  description: "Descripción",
  group: "Grupo",
  delete: "Eliminar",
  parking_reservation: "Reserva de cocheras",
  export_to_excel: "Exportar",
  users: "Usuarios",
  error_add_empty_parkingNames: "No ha cargado ningún nombre de cochera",
  error_add_empty_parkingGroupNames:
    "No ha cargado ningún nombre de grupo de cocheras",
  parking_free: "Libre",
  parking_reserved: "Reservada",
  parking_canceled: "Cancelada",
  parking_approved: "Aprobado",
  parking_rejected: "Rechazada",
  parking_used: "Utilizada",
  parking_unused: "Sin uso",
  parking_using: "En uso",
  parking_released: "Liberada",
  parking_expired: "Vencida",
  parking_pending: "Pendiente",
  parking: "Cochera",
  parkings: "Cocheras",
  parking_groups: "Grupos de cocheras",
  parking_reserv: "Reservas",
  parking_reports: "Informes de uso",
  parking_users: "Usuarios",
  confirm_reject_parkingReserv: "¿Rechazar reserva?",
  user: "Usuario",
  total: "Total",
  filter: "Filtrar",
  percentage: "Porcentaje",
  change_report_by_parkings: "Ver por grupo de cocheras",
  change_report_by_users: "Ver por Usuarios",
  report_user_employee: "Usuarios<br/>Empleado",
  report_user_employee_excel: "Usuarios Empleado",
  report_parkingLot: "Cochera<br/>Asignada",
  report_parkingLot_excel: "Cochera Asignada",
  report_total: "Total<br/>Reservas YTD",
  report_total_excel: "Total Reservas YTD",
  report_used: "Total Reservas<br/>Utilizadas",
  report_used_excel: "Total Reservas Utilizadas",
  report_percent: "% Utilización<br/>Reservas",
  report_percent_excel: "% Utilización Reservas",
  report_group: "Grupo",
  dete_from: "Desde",
  date_to: "Hasta",
  change_list_to_details: "Ver Detalle",
  change_list_to_parkings: "Ver por cochera",
  status_reserved: "Reservada",
  status_approved: "Aprobado",
  status_canceled: "Cancelada",
  status_rejected: "Rechazada",
  status_used: "Utilizada",
  status_unused: "Sin uso",
  status_using: "Ocupada",
  status_acepted: "Aceptado",
  status_pending: "Pendiente",
  status_finished: "Finalizada",
  status_expired: "Vencido",
  reserv_create_date: "Fecha-Hora<br/>Generación",
  reserv_create_date_excel: "Fecha-Hora Generación",
  reserv_date: "Fecha<br/>Reservada",
  reserv_date_excel: "Fecha Reservada",
  reserv_user_employee: "Usuario<br/>Empleado",
  reserv_user_employee_excel: "Usuario Empleado",
  reserv_parkingLot: "Cochera<br/>Reservada",
  reserv_parkingLot_excel: "Cochera Reservada",
  reserv_modification_date: "Fecha-Hora<br/>Modificación",
  reserv_modification_date_excel: "Fecha-Hora Modificación",
  reserv_travel_excel: "Viaje",
  establishment_hour: "Horario del establecimiento",
  until_date: "Hasta el %%DATE%%",
  full_day_from_hour_to_hour:
    "%%DAY_NAME%% %%FULL_DAY%%, de %%FROM_HOUR%%hs a %%TO_HOUR%%hs",
  pagination: "%%FROM%%-%%TO%% de %%TOTAL%%",
  confirm_desk_unsetDesksUser: "¿Desasignar usuario?", //no usado
  confirm_desk_remove: "¿Remover escritorio con {{countDesks}} reservas?",
  confirm_deskGroup_remove:
    "¿Remover grupo de escritorios con {{countDesks}} reservas?",
  confirm_reject_DeskReserv: "¿Rechazar reserva?",
  desk: "Escritorio",
  desks: "Escritorios",
  desks_reservation: "Reserva de escritorios",
  desks_groups: "Grupos de escritorios",
  desks_reserv: "Reservas",
  desks_reports: "Informes de uso",
  desks_users: "Usuarios",
  desk_free: "Libre",
  desk_reserved: "Reservada",
  desk_canceled: "Cancelada",
  desk_rejected: "Rechazada",
  desk_used: "Utilizada",
  desk_unused: "Sin uso",
  desk_using: "En uso",
  desk_released: "Liberada",
  desk_finished: "Finalizada",
  desk_expired: "Vencida",
  desk_groups: "Grupos de Escritorios",
  desk_reserv: "Reservas",
  desk_reports: "Informes de uso",
  desk_users: "Usuarios",
  desk_layout_button_load: "Cargar Plano de escritorios",
  desk_layout_button_show: "Ver Plano de escritorios",
  desk_layout_button_save: "Guardar",
  desk_layout_button_cancel: "Cancelar",
  desk_layout_button_close: "Cerrar",
  desk_layout_button_accept: "Aceptar",
  desk_layout_title_load: "Plano de escritorios",
  desk_layout_download_filename: "plano de escritorios",
  desk_layout_title_show: "Ver Plano de escritorios",
  desk_layout_label_formats: "*Formatos aceptados .jpg, .png, .docx o .pdf.",
  desk_layout_label_formats_error:
    "Los formatos permitidos son .png, .jpg, .docx o .pdf",
  desk_layout_label_maxsize_error:
    "El archivo debe tener un tamaño inferior a 8 MB",
  desk_layout_label_dragdrop:
    "Suelte aquí el archivo para cargarlo instantáneamente",
  desk_layout_label_delete_confirm: "¿Desea eliminar el Plano de escritorios?",
  desk_qr_print_all: "IMPRIMIR TODOS",
  desk_qr_print: "IMPRIMIR QR",
  desk_flyer_title: "CHECK-IN ESCRITORIO",
  desk_flyer_description1: "Escanea el QR desde la sección",
  desk_flyer_description2: " Mi QR",
  desk_flyer_description3: " de tu aplicación",
  desk_flyer_description4: " PassApp.",
  desk_flyer_footer: "Tecnología de Gestión.",
  add_desks: "Agregar escritorios",
  edit_desk: "Editar escritorio",
  add_desk_group: "Agregar grupo de escritorios",
  edit_desk_group: "Editar grupo de escritorios",
  assigned_desk: "Escritorios asignados",
  confirm_delete_deskGroup: "¿Remover grupo de escritorios?",
  confirm_uncheck_deskGroup: "¿Remover usuario del grupo?",
  unsetDeskUser: "Desasignar usuario",
  confirm_desk_unsetDeskUser: "¿Remover usuario del escritorio?",
  change_list_to_desks: "Ver por escritorio",
  change_report_by_desks: "Ver por grupo de escritorio",
  report_desk: "Escritorio<br/>Asignado",
  report_desk_excel: "Escritorio Asignado",
  reserv_desk: "Escritorio<br/>Reservado",
  reserv_desk_excel: "Escritorio Reservado",
  error_getting_desk: "Error al obtener los escritorios",
  error_add_desk: "Error al agregar los escritorios",
  error_edit_desk: "Error al editar el escritorio",
  error_deleting_desk: "Error al borrar el escritorio",
  error_getting_desk_group: "Error al obtener los grupos de escritorios",
  error_getting_desk_employees: "Error al obtener el listado de usuarios",
  error_add_desk_group: "Error al agregar el grupo de escritorio",
  error_updating_desk_group: "Error al editar el grupo de escritorio",
  error_deleting_desk_group: "Error al borrar el grupo de escritorio",
  error_add_employeeDeskGroup: "Error al agregar el usuario",
  error_remove_employeeDeskGroup: "Error al borrar el usuario",
  error_getting_reservations_by_desks:
    "Error al obtener reservas por escritorios",
  day: "Día",
  month: "Mes",
  year: "Año",
  invalid_birthdate_future: "La fecha no puede ser mayor que hoy",
  invalid_birthdate: "Fecha inválida",
  january: "Enero",
  february: "Febrero",
  march: "Marzo",
  april: "Abril",
  may: "Mayo",
  june: "Junio",
  july: "Julio",
  august: "Agosto",
  september: "Septiembre",
  october: "Octubre",
  november: "Noviembre",
  december: "Diciembre",
  send_sms: "Enviar SMS",
  activate_account: "Activación de cuenta",
  active_by_whatsapp: "Activar vía Whatsapp Web",
  text_active_by_whatsapp:
    "Oprima el botón Activar vía Whatsapp Web, y envíe el mensaje que se generará automáticamente",
  active_by_sms: "Activar vía SMS",
  text_active_by_sms:
    "Si no cuenta con Whatsapp puede activar la cuenta vía SMS.",
  supplier_data: "Datos del proveedor",
  supplier_or_company_name: "Nombre proveedor o empresa",
  sector: "Rubro",
  comments_for_the_guard: "Comentario para la guardia",
  select_type_of_supplier: "Seleccione tipo de proveedor",
  error_guest_type: "Debe seleccionar un tipo de invitado",
  error_guest_type_provider_corporative:
    "Este complejo no admite avisos a porteria de tipo proveedor",
  adm_title: "Activacion de cuenta Administrador de Empresa",
  adm_tyc: "como Administrador de Empresa",
  advice_1: "Sugerencia:",
  advice_2:
    "si ya cuentas con un usuario PassApp, inicia sesión con el mismo así queda vinculado a tu usuario administrador de empresa.",
  adm_confirm:
    "Esta acción vinculará tu usuario PassApp a tu usuario administrador de empresa.",
  adm_no_user_exist:
    "El email ingresado aún no corresponde a un usuario PassApp.",
  adm_register_new_user: "¿Desea crear un nuevo usuario?",
  adm_register:
    "Para crear un usuario Administrador de Empresa completa los siguientes datos.",
  adm_register_tyc: "para el alta de mi cuenta",
  adm_success:
    "Su cuenta ha sido activada correctamente y su usuario ha quedado asociado al perfil administrador de su empresa.",
  adm_login_repeat: "Volver a intentarlo",
  adm_login_register: "Crear usuario",
  access_event_menu: "Eventos de acceso",
  access_event_tab: "Eventos de acceso",
  access_event_export_filename: "Eventos de acceso",
  access_event_filter_button: "Filtro",
  access_event_table_incoming: "Ingresante",
  access_event_table_type: "Tipo",
  access_event_table_date: "Fecha",
  access_event_table_company: "Empresa/s",
  access_event_table_host: "Anfitrión",
  access_event_table_manager: "Mánager",
  access_event_table_authorizationType: "Tipo de autorización",
  access_event_table_receptionist: "Personal de seguridad",
  access_event_table_norows: "Sin resultados",
  menu_my_qr: "Mi QR",
  send_pass_contact_button: "Enviar pase a contactos seleccionados",
  send_pass_contact_new_button: "Enviar pase a un nuevo contacto",
  send_pass_contact_search: "Buscar...",
  send_pass_contact_find:
    "Se sincronizaron {{ amountContact }} usuarios passapp",
  send_pass_contact_amount_title: "Cantidad de invitados ({{ amountContact }})",
  send_pass_contact_norows: "Sin contactos vinculados aún",
  send_pass_contact_iphone_setting_1:
    "Para sincronizar los contactos de su teléfono en la web de PassApp, debe activar la siguiente configuración. Ingrese a <b>CONFIGURACION</b>, vaya a <b>SAFARI</b> y allí siga estos pasos:",
  send_pass_contact_iphone_setting_2: "> Seleccione <b>Avanzado</b>:",
  send_pass_contact_iphone_setting_3:
    "> Busque la opción <b>Funciones experimentales</b>:",
  send_pass_contact_iphone_setting_4:
    ">  Y por ultimo active la funcion <b>Contact Picker API</b>:",
  send_pass_contact_iphone_setting_cancel: "Cancelar",
  send_pass_contact_iphone_setting_toConfig: "Ir a Configuración",
  my_qr_title: "Mi Código QR",
  no_row_passes: "No existen invitados aún",
  no_row_notifications: "No hay notificaciones",
  send_pass_contact_sync_button: "Sincronizar nuevo contacto",
  send_pass_share: "Compartir invitación",
  pass_request_menu: "Solicitudes de pase",
  pass_request_title: "Solicitudes de pase",
  no_row_requests: "No hay solicitudes de pase",
  bottom_menu_notification: "Notificaciones",
  bottom_menu_myguest: "Mis invitados",
  bottom_menu_myqr: "Mi QR",
  bottom_menu_passrequest: "Solicitudes",
  pass_title_ocasional: "Ocasionales",
  pass_title_permanent: "Permanentes",
  pass_label_no_invited: "Sin invitados aún",
  pass_label_no_results: "No hay coincidencias",
  facephoto_dialog_message:
    "Para utilizar los dispositivos de reconocimiento facial de su complejo, se requiere cargar una foto selfie.",
  facephoto_dialog_cancel: "Cargar selfie",
  facephoto_dialog_confirm: "Recordarme más tarde",
  facephoto_menu: "Actualizar selfie",
  facephoto_upload_error: "Ocurrió un error al subir la selfie",
  facephoto_upload_success: "Selfie actualizada correctamente",
  //charters
  charter_text_dialog_change_month:
    "Si cambia de mes sin confirmar las reservas perderá los cambios realizados",
  charter_button_save_changes: "CONFIRMAR CAMBIOS",
  charter_button_discard_changes: "Descartar los cambios no guardados",
  charter_button_repeat_last_month: "Repetir pedido del mes anterior",
  charter_january: "ENERO",
  charter_february: "FEBRERO",
  charter_march: "MARZO",
  charter_april: "ABRIL",
  charter_may: "MAYO",
  charter_june: "JUNIO",
  charter_july: "JULIO",
  charter_august: "AGOSTO",
  charter_september: "SEPTIEMBRE",
  charter_october: "OCTUBRE",
  charter_november: "NOVIEMBRE",
  charter_december: "DICIEMBRE",
  charter_user_select_all: "HABILITAR A TODOS",
  charter_user_unselect_all: "DESHABILITAR A TODOS",
  charter_user_dialog_text:
    "¿Está seguro que desea deshabilitar la reserva de charters a todos los empleados?",
  charter_report_travel: "VIAJE<br/>  ",
  charter_report_date_reserved: "FECHA<br/> RESERVADA",
  charter_report_user_employee: "USUARIO<br/> EMPLEADO",
  charter_report_date_generate: "FECHA HORA<br/> GENERACION",
  charter_report_status: "ESTADO",
  charter_report_date_hour: "FECHA HORA<br/> MODIFICACION",
  charter_report_available_seats: "ASIENTOS<br/> DISPONIBLES",
  charter_report_reserved_seats: "ASIENTOS <br/>RESERVADOS",
  charter_report_percentage_use: "% DE UTILIZACION",
  error_getting_charter_employee: "error al obtener la lista de  empleados",
  error_setting_charter_employee_set: "error al habilitar empleado",
  error_setting_charter_employee_unset: "error al deshabilitar empleado",
  charter_report_view_date: "VER POR FECHA",
  charter_report_by_user: "TOTAL RESERVAS YTD",
  charter_report_reserves_used: "TOTAL RESERVAS UTILIZADAS",
  charter_report_percentage_reserves: "% DE UTILIZACION RESERVAS",
  charter_report_excel_available_seat: "Asientos Disponibles",
  charter_report_excel_reserved_seat: "Asientos Reservados",
  charter_report_excel_percentage_use: "% de utilizacion",
  charter_report_excel_date_generate: "Fecha hora de generacion",
  charter_reserve_confirm_reservations: "CONFIRMAR RESERVAS",
  charter_reserve_txt_dialog_available:
    "Algunas reservas no pudieron ser guardadas por falta de disponibilidad",
  charter_reserve_txt_dialog_invalid:
    "No se pueden hacer dos reservas de viajes tan próximos en sus horarios de inicio",
  charter_reserve_msg_missing_dates: "No se seleccionó reservas",
  charter_reserve_msg_confirm_dates: "Reservas confirmadas",
  charter_reserve_january: "ENERO",
  charter_reserve_february: "FEBRERO",
  charter_reserve_march: "MARZO",
  charter_reserve_april: "ABRIL",
  charter_reserve_may: "MAYO",
  charter_reserve_june: "JUNIO",
  charter_reserve_july: "JULIO",
  charter_reserve_august: "AGOSTO",
  charter_reserve_september: "SEPTIEMBRE",
  charter_reserve_october: "OCTUBRE",
  charter_reserve_november: "NOVIEMBRE",
  charter_reserve_december: "DICIEMBRE",
  charter_reserve_error_send: "Error al enviar reservas",
  charter_reserve_description_travel: "Descripción del viaje",
  charter_reserve_change_month: "Volver a la pantalla anterior",
  charter_reserve_dialog_confirm_reserves:
    "Confirmar reservas y cambiar de mes",
  charter_reserve_dialog_change_mont_txt:
    "Si cambia de mes sin confirmar las reservas perderá los cambios realizados",
  charter_reserve_whitout_reserves:
    "Su empresa no reservó ningún cupo en este mes para este viaje. Si desea reservar este viaje, verifique en meses futuros si hay cupos disponibles, y en caso contrario, comuníquese con su administrador de empresa para que solicite los cupos de este viaje.",
  charter_reserve_report_travels_op: "VER POR VIAJES",
  charter_reserve_msj_blocked_days:
    "Para editar cupos del mes corriente debe contactarse con la administración del complejo",
  charter_reserve_without_reserves_msj: "Debe ingresar reservas",
  charter_reserve_color_detail_green: "Con lugar disponible",
  charter_reserve_color_detail_red: "Sin lugar disponible",
  charter_reserve_max_reserve_msj:
    "No pueden generarse reservas con tanta anticipación en este viaje",
  charter_travel_tab: "Pedido de viajes",
  charter_reserve_tab: "Reservas",
  charter_usage_report_tab: "Informes de usos",
  charter_users: "Usuarios",
  charters: "charters",
  charter_reservation: "Reserva de charters",
  charter_reserve_use_compliance: "Cumplimiento de uso",
  charter_reserve_cancel_reservation:
    "¿Está seguro que desea cancelar esta reserva?",
  chartar_reserve_of: "de",
  charter_reserve_no_reservations: "No hay reservas realizadas",
  monday: "LUNES",
  tuesday: "MARTES",
  wednesday: "MIERCOLES",
  thursday: "JUEVES",
  friday: "VIERNES",
  saturday: "SABADO",
  sunday: "DOMINGO",
  charter_reserve_cancel_msj: "Reserva cancelada correctamente",
  passes_mail_input: "Ingresar email para activar tu cuenta PassApp",
  passes_mail_continue: "Continuar",
  passes_mail_repeat_msj:
    "El email ingresado ya existe en passapp, por favor intenta ingresando uno distinto",
  passes_mail_active_msj:
    "Activa tu cuenta accediendo al link enviado a tu casilla de correo electrónico",
  passes_mail_resend_title: "Reenviar email",
  passes_mail_change_msj: "Cambiar dirección de email",
  passes_mail_entry_msj:
    "Para activar tu cuenta passapp deberás entrar al siguiente enlace.",
  passes_mail_hello: "Hola",
  passes_mail_success_send: "Email enviado correctamente",
  //charters
  //spaces
  space_reservation: "Reserva de espacios",
  space_reserve_tab: "Reservas",
  space_reserve_date_generation: "FECHA-HORA<br/>  GENERACIÓN",
  space_reserve_date_resverve: "FECHA<br/>  RESERVA",
  space_reserve_hour: "HORA<br/>  DESDE-HASTA",
  space_reserve_user_employee: "USUARIO<br/>  EMPLEADO",
  space_reserve: "ESPACIO<br/>  RESERVADO",
  space_reserve_status: "ESTADO",
  space_reserve_date_modification: "FECHA-HORA<br/>  MODIFICACIÓN",
  space_reserve_credits: "CRÉDITOS",
  space_reserve_money: "DINERO",
  space_reserve_date_generation_excel: "fecha hora generacion",
  space_reserve_date_resverve_excel: "fecha reserva",
  space_reserve_hour_excel: "hora desde hasta",
  space_reserve_user_employee_excel: "usuario empleado",
  space_reserve_excel: "espacio reservado",
  space_reserve_date_modification_excel: "fecha hora modificación",
  space_reserve_credits_excel: "créditos",
  space_reserve_money_excel: "dinero",
  space_credit_tab: "Créditos por empleado",
  space_credit_warning_title: "AVISO IMPORTANTE:",
  space_credit_warning_text:
    "Le sugerimos revisar las reservas futuras de los siguientes empleados, ya que la nueva cantidad de créditos es menor al total de créditos consumidos en reservas futuras ya confirmadas.",
  space_credit_success: "Datos guardados correctamente",
  space_credit_column: "Créditos individuales",
  space_credit_balance: "Saldo en créditos",
  space_credit_balance_tab: "Saldo por mes",
  space_credit_balance_year_month: "mes y año",
  space_credit_money_excel: "moneda",
  space_balance_money_excel: "saldo",
  space_balance_balance_name: "CRÉDITOS",
  //spaces
};
export default es;
